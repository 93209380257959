<template>
  <footer id="footer">

    <div class="newsletter">
      <div class="container">
        <div class="newsletter-content">
          <div class="newsletter-text">
            <h3>Mantenha-se informado</h3>
            <h4>Receba nossa newsletter</h4>
          </div>
          <div class="newsletter-form">
            <input class="form-control" type="email" placeholder="Digite seu e-mail" required>
            <button class="btn " type="submit">Enviar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-main">
      <div class="container">
        
        <div class="footer-top-section">
         
          <div class="contact-info" style="color : black; ">

            <div class="contact-item">
                <div>
                  <a href="tel:+554132051333" >
                    <i class="bi bi-telephone-outbound m-r16 "></i>
                    41 3205-1333
                  </a>
                </div>
                <div>
                  <a href="https://goo.gl/maps/RvKqPfXkoQ31Lfdg9" target="_blank">
                    <i class="bi bi-geo-alt-fill m-r16"></i>
                    ONDE ESTAMOS?
                  </a>
                </div>
            </div>

            <div class="social-links">
                <a href="https://www.facebook.com/creditalloficial" target="_blank">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/creditalloficial/" target="_blank">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/creditall-holding-empresarial-eireli/about/" target="_blank">
                  <i class="bi bi-linkedin"></i>
                </a>
                <a href="https://www.youtube.com/@creditallmarketing5282" target="_blank">
                  <i class="bi bi-youtube"></i>
                </a>

                <a href="https://www.google.com/search?q=creditall+holding+empresarial+eireli" target="_blank">
                  <i class="bi bi-google"></i>
                </a>
            </div>
          </div>
        </div>

        <div class="footer-bottom-section">
          <div class="copyright">
            <div class="divCopyrightFooter">
              <div>
                <span>
                  <a href="/#servicos">Garantia de Crédito</a> |
                  <a href="/#garantiaaluguel">Garantia de Aluguel</a> |
                  <a href="/#plataformacredito">Plataforma de Crédito</a> |
                  <a href="/recorrencia-garantida">Recorrência Garantida</a>
                </span>
              </div>

              <div>
                <span>
                  <a href="/">Inicio</a> |
                  <a href="/lgpd">LGPD</a> |
                  <a href="https://siscredit.com.br/sisrepr/">Licenciado</a> |
                  <a href="/desenvolvedores">Área destinada a desenvolvedores</a>
                </span>
              </div>

              <div>
                <p>
                  © 2008 - {{ new Date().getFullYear() }} |
                  <a href="/">Creditall Holding Empresarial</a> - Todos os direitos reservados
                </p>
              </div>
            </div>
          </div>

          <div class="logo">
            <img src="/img/logo_creditall.2941d2d9.png" alt="Logo Creditall">
          </div>
        </div>
      </div>
      
    </div>
  </footer>
</template>

<style scoped>

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}


.footer-main {
  background-color: #fff;
  padding: 3rem 0;
  text-align: center;
}


.contact-info {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.contact-item {
  display: flex;
  gap: 20px;
  align-items: center;
}

.contact-item i {
  font-size: 20px;
  padding: 10px;
  border: 1px solid #bf1b39;
  border-radius: 5px;
  color: #bf1b39;
  margin: 0 10px 0 0;
}

.contact-item a {
  color: #bf1b39;
}

.contact-item a:hover {
  transform: translateX(-3px);
  transition: transform 0.2s ease;
}

.m-r16 {
  margin-right: 4px;
}

.social-links a {
  font-size: 21px;
  padding: 10px;
  border-radius: 5px;
  background-color: #848688;
  color: #fff;
  margin: 0 5px;
}

 
.footer-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 1rem 0;
}

.copyright {
  display: flex;
  align-items: center;
  color: black;
  font-size: 10px;
  flex: 0 0 auto;
}

.divCopyrightFooter {
  text-align: left;
}

.divCopyrightFooter span {
  font-size: 14px;
  margin-bottom: 10px;
}

.logo {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
}

.logo img {
  max-width: 180px;
}

.newsletter {
  background-color: #848688;
  color: white;
  padding: 50px;
}

.newsletter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.newsletter-text h3 {
  font-size: 30px;
  font-weight: 400;
  color: white;
  margin: 0;
}

.newsletter-text h4 {
  font-size: 18px;
  font-weight: 300;
  margin: 5px 0 0;
  color: white;
}

.newsletter-form {
  display: flex;
  gap: 10px;
}

.newsletter-form input {
  width: 350px;
  height: 35px;
  margin: 0 5px;
}

.newsletter-form button {
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  background-color: rgb(191, 27, 57);
  color: rgb(255, 255, 255);
  display: inline-block;
}

 
.icon-circle {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

 
@media (max-width: 768px) {
  .contact-item a {
    font-size: 12px;
  }

  .divCopyrightFooter {
    text-align: center;
  }

  .contact-info,
  .footer-bottom-section {
    align-items: center;
    flex-direction: column;
  }

  .newsletter-content {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .newsletter-form {
    flex-direction: column;
    width: 100%;
  }

  .newsletter-form input {
    min-width: auto;
    width: 100%;
  }

  .contact-info {
    flex-direction: column;
    gap: 1rem;
  }
}

</style>

<script>
  export default {
    name: 'Footer',
    methods: {
      handleSubmit(e) {
        e.preventDefault()
        this.$router.push('/quero-ser-cliente')
        window.scrollTo(0, 0)
      }
    }
  }
</script>
