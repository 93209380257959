<template>
  <section id="parceiros" class="parceiros">
    <div class="container">
      <div class="section-title text-center">
        <span class="subtitle">Parceiros</span>
        <h2 class="title">Nossos Parceiros</h2>
        <div class="title-underline"></div>
      </div>
      <div class="logos-wrapper">
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-2 col-md-3 col-6 text-center mb-4" v-for="(partner, index) in partners" :key="index">
              <div class="partner-logo d-flex justify-content-center align-items-center" :data-aos-delay="100 * (index + 1)">
                <img :src="partner.logo" class="img-fluid" :alt="partner.name">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Parcerias',
  data() {
    return {
      partners: [
        { name: 'Pecunia', logo: require('../../public/assets/images/clients/pecunia2.png') },
        { name: 'RGS', logo: require('../../public/assets/images/clients/RGS.png') },
        { name: 'Partner 3', logo: require('../../public/assets/images/clients/3.png') },
        { name: 'DS Logo', logo: require('../../public/assets/images/clients/DS-Logo-Black1.png') },
        { name: 'Golden Bravo', logo: require('../../public/assets/images/clients/goldenbravo.png') }
      ]
    };
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.parceiros {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  font-family: 'Roboto', sans-serif;
}

.section-title {
  margin-bottom: 60px;
  position: relative;
}

.section-title .subtitle {
  color: #bf1b39;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section-title .title {
  font-size: 42px;
  font-weight: 700;
  color: #212529;
  margin: 0 0 20px;
}

.title-underline {
  width: 80px;
  height: 4px;
  background: #bf1b39;
  margin: 0 auto;
  position: relative;
}

.title-underline:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -6px;
  width: 40px;
  height: 4px;
  background: #bf1b39;
  transform: translateX(-50%);
}

.partner-logo {
  width: 100%;
  height: 150px; 
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.08);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-logo:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  transition: 0.5s;
}

.partner-logo:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0,0,0,0.1);
}

.partner-logo:hover:before {
  left: 100%;
}

.partner-logo img {
  max-height: 90px;
  width: auto;
  margin: 0 auto;
  transition: all 0.3s ease;
  filter: grayscale(100%);
}

.partner-logo:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}
</style>
