<template>
  <TopBarGA />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/#servicos">Garantia de Aluguel</a></li>
        </ol>
        <h2>Garantia de Aluguel</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">
    <div class="col-lg-12">
      <div style="margin-top: 50px"></div>

      <div class="row">
        <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <h5 style="border-bottom: 1px solid #BF1B39">Sobre o Produto</h5>
          <br />
          <p style="font-size: 13px">
            Mais praticidade para quem quer alugar, e garantido para imobiliárias e proprietários!
          </p>
          <p style="font-size: 13px">
            Alugar um imóvel ficou muito mais fácil com o CreditallPay®. Você não precisa se preocupar com fiador,
            depósito caução ou comprovação de renda; alugue o imóvel que desejar, com total segurança e agilidade.
          </p>
          <p style="font-size: 13px">
            Alugar um imóvel sempre foi burocrático e acessível para poucos, um dos principais pontos era a garantia
            dada na locação. Até há bem pouco tempo, a figura do fiador com imóvel quitado no nome, era praticamente
            a única forma capaz de garantir o recebimento dos aluguéis aos proprietários, mas atualmente, já existem
            outras modalidades a fim de atender as necessidades tanto dos inquilinos quanto as dos donos de imóveis.
          </p>
          <p style="font-size: 13px">
            São soluções que passam longe das demoradas filas e burocracias dos cartórios, com infinitos carimbos e
            assinaturas, além do constrangimento de se procurar um fiador. Estamos falando do CreditallPay® Aluguel Garantido.
          </p>
          <p style="font-size: 13px">
            A Creditall disponibiliza essa modalidade, totalmente online e a análise de crédito é realizada em até 10 segundos,
            validamos o perfil e potencial de pagamento do inquilino que, em questão de segundos, já pode ter o seu cadastro
            aprovado para alugar. Tudo isso com as menores taxas do mercado.
          </p>
          <p style="font-size: 13px">
            Além do inquilino, o proprietário também tem vantagens com o CreditallPay®, como a garantia aplicada
            ao recebimento dos aluguéis, conta ainda com garantia contra danos na devolução do imóvel e, em casos
            de ação de despejo, assessoria jurídica gratuita.
          </p>
          <p style="font-size: 13px">
            Seja hoje mesmo uma imobiliária parceira e comece a oferecer mais esta moderna modalidade de garantia,
            que facilita a vida de inquilinos e proprietários!
          </p>
          <br />

          <div class="row">
            <div class="col-md-6" style="margin-left: 20px">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Sem burocracias, 100% digital</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Garantia de recebimento</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Agilidade no fechamento dos contratos de aluguel</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Garantia de recebimento das locações</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Análise de crédito eficiente para diminuir inadimplência</p>
            </div>
            <div class="col-md-4" style="margin-left: 20px">
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Pagamento anual dividido em até 12x sem juros no boleto, Pix ou cartão</p>
              <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> ⠀Rapidez no processo de locação do seu imóvel</p>
              <div style="margin-top: 75px"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-left">

          <h5 style="border-bottom: 1px solid red">Pontos Positivos</h5>
          <br />
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Para as imobiliárias e corretores, amplia-se o número de locações.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Para os proprietários dos imóveis, ficam menos tempo com o imóvel vazio.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Para quem aluga, não precisa de fiador, caução ou comprovação de renda.</p>
          <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Dentre muitos outros benefícios e vantagens.</p><br>
          <img src="../../public/assets/images/casas_ga.jpg" class="img-fluid rounded shadow">
        </div>
      </div>
    </div>
    </div>
    <div style="margin-top: 50px"></div>

    <div class="col-lg-12">
    <div class="mb-5">
      <h5 class="text-center">Entre em contato para maiores informações</h5>
      <div class="d-grid gap-2 col-4 mx-auto">
        <a href="/cadastro-garantia-aluguel" class="btn btn-danger" style="background: #BF1B39; border-radius: 0px;">Fale conosco</a>
      </div>
    </div>
    </div>
  </section>

  <FooterGA />
</template>

<script>
import FooterGA from "../components/FooterGA.vue";
import TopBarGA from "../components/TopBarGA.vue";

export default {
  name: "SobreGA",
  components: {
    TopBarGA,
    FooterGA,
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}

@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
</style>
