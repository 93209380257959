<template>
    <section class="bord service-ga">
        <div class="container">

            <div class="title" data-aos="fade-right">
                <h1 style="color: rgb(255, 255, 255); font-weight: bold; margin: 80px 0px;">
                    <font face="Sansation_Regular">Serviços e soluções para acompanhar a sua empresa em cada etapa do seu negócio.</font>
                </h1>
                
                <h5 style="color: rgb(255, 255, 255); font-weight: 100; margin: 20px 0px;">
                    <font face="Sansation_Regular">Protegemos o seu negócio, protegemos o seu mercado.</font>
                </h5>

                <div class="divider"></div>

                <h1 style="color: rgb(255, 255, 255); font-weight: bold; margin: 20px 0px;">
                    <font face="Sansation_Regular">Garantia de Crédito.</font>
                </h1>

                <h5 style="color: rgb(255, 255, 255); font-weight: 100;">
                    <font face="Sansation_Regular">Para impulsionar o crescimento da sua empresa a chegar a novos mercados, sempre de maneira <br> rentável e segura.</font>
                </h5>

                <div class="divSaiba">
                    <div class="iconSaiba">
                        <i class="bi bi-caret-right" style="color: #bf1b39;"></i>
                    </div>
                    <a class="textSaiba" href="/quero-ser-cliente">Saiba Mais</a>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

    .service-ga {
        background-image: url('/assets/images/BANNERS0001.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        padding: 20px;
        margin-top: 40px;
    }

    .divider {
        height: 10px;
        border-radius: 10px;
        background-color: #bf1b39;
        margin: 20px 0;
    }

    .divSaiba {
        display: flex;
        align-items: center;
        font-variant: 20px;
        margin-top: 30px;
    }

    .iconSaiba {
        color: #fff;
        font-size: 24px;
        margin-right: 10px;
    }

    .textSaiba {
        color: #fff;
        text-decoration: none;
        font-size: 18px;
    }

    .textSaiba:hover {
        text-decoration: underline;
    }
</style>

<script>
    export default {
        name: 'ServiceGa'
    }
</script>