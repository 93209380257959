
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './utils/global.css';


const app = createApp(App).use(router)


app.use(AOS.init());

app.mount('#app')


function handleScroll() {
    const navbar = document.getElementById('header_navbar');
    const scrollTop = document.documentElement.scrollTop;
    
    if (navbar) {
      if (scrollTop > 100) {
        navbar.style.backgroundColor = '#fff';
        navbar.style.boxShadow = '0 0 15px rgba(0,0,0,0.1)';
      } else {
        navbar.style.backgroundColor = 'transparent';
        navbar.style.boxShadow = 'none';
      }
    }
}


document.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
});


document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        
        if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth'
        });
        }
    });
});