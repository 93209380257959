<template>
  <section id="quero" class="quero">
    <div style="margin-top: 50px;"></div>

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row justify-content-center">
              <div class="col-lg-6 justify-content-start content" data-aos="fade-right">
                <div div class="section-title" data-aos="fade-up">
                  <div class="section-title">
                   
                    <h2>Quero ser cliente</h2>
                    <p style="font-size: 18px">
                    Comece hoje a trabalhar com a Creditall e tenha todas essas vantagens para o seu comércio com segurança e o melhor atendimento
                    </p>
                    <a href="/quero-ser-cliente" class="btn btn-danger" style="margin-top: 2rem; background: #BF1B39; border-radius: 0px;">Saiba mais</a>
                  </div>
                </div>
              </div>
                  <div class="col-lg-5 d-flex justify-content-center" data-aos="fade-left">
                    <img src="assets/images/quero_ser_cliente.jpg" class="img-fluid rounded shadow" style="shadow" width="500" height="300" alt=""/>
                  </div>
            </div>
            </div>
          </div>
        </div>
  </section>
</template>

<script>
export default {
  name: "SectionQueroSerCliente",
};
</script>

<style scoped>
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}
.section-title span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}
.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title span {
        font-size:40px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title span {
        font-size:48px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title span {
        font-size:35px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title span {
        font-size: 30px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title span {
        font-size:25px;
        top: 30px;
    }
}

@media only screen and (min-width:300) and (max-width:440){
    .section-title span {
        font-size:15px;
        top: 30px;
    }
}
</style>
