<template>
  <HeaderRecorrencia />

  <div style="margin-top: 50px"></div>
  <section id="inicio" class="inicio">
    <div style="margin-top: 50px"></div>
    <div class="container">
      <div class="col-lg-12">
        <div class="row justify-content-center">
          <div class="col-lg-5 d-flex" data-aos="fade-right">
            <img src="../../public/assets/images/sobre_pix.jpg" class="img-fluid rounded shadow" alt=""/>
          </div>

          <div class="col-lg-6 pt-4 pt-lg-0 justify-content-center ml-2" data-aos="fade-right">
            <div class="section-title-inicio" data-aos="fade-up">
              <div class="section-title-inicio">
                <h2>VENDA E PARCELE PELO<br> CreditallPay® Recorrência Garantida</h2>
              </div>
            </div>
            <p>Você já conhece a praticidade das cobranças automáticas no cartão de crédito, uma forma simples e segura de garantir pagamentos recorrentes a qualquer momento!</p>
            <p>Vender parcelado no cartão sem consumir o limite disponível do cliente nunca foi tão fácil e a melhor parte é que a Creditall garante 100% o recebimento da compra. Ideal para todos os negocios.</p>
            <a href="/sobre-pix-parcelado-garantido" class="btn btn-danger" style="margin-top: 1rem; border-radius: 3px;">Conheça nosso novo produto</a>
            <div style="margin-top: 25px">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="comofuncionaparceiro" class="comofuncionaparceiro">

    <div class="container">
      <div class="section-title-comofuncionaparceiro">
        <h2>PARA NOSSOS PARCEIROS <br>
          COMO FUNCIONA O CreditallPay® Recorrência Garantida</h2>
        <p>Vender parcelado no cartão sem consumir o limite disponível do cliente nunca foi tão fácil! Com a Recorrência Garantida da Creditall, seu negócio tem a garantia de 100% do recebimento das vendas. Ideal para empresas de qualquer segmento que operem com vendas parceladas. Seu cliente parcela a compra precisando ter disponível apenas o valor da primeira parcela, enquanto você recebe a garantia total do pagamento. Uma solução completa que aumenta suas vendas, reduz a inadimplência e simplifica a gestão financeira do seu negócio.</p>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box" style="height: 100%">
            <div class="icon"><p class="fw-bold" style="font-size: 20px">1</p></div>
            <h4 style="border-bottom: 1px solid red">Primeiro a gente se conhece</h4>
            <div class="text">
              <p>O parceiro se credencia e receberá um login e senha, seguido de um treinamento online para utilização da plataforma de maneira otimizada.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box" style="height: 100%">
            <div class="icon"><p class="fw-bold" style="font-size: 20px">2</p></div>
            <h4 style="border-bottom: 1px solid red">Você vende na Recorrência</h4>
            <div class="text">
              <p>As vendas na Recorrência Garantida são efetuadas seguindo as regras habituais da parceria.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
          <div class="icon-box" style="height: 100%">
            <div class="icon"><p class="fw-bold" style="font-size: 20px">3</p></div>
            <h4 style="border-bottom: 1px solid red">Recebimentos</h4>
            <div class="text">
              <p>O recebimento das vendas ocorrem no fluxo das parcelas, através da liquidação da fatura de seus clientes.</p>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  </section>

  
  <div style="margin-top: 75px"></div>
  <section id="comofuncionacliente" class="comofuncionacliente">
    <div class="page-center">
      <div class="col-lg-12">
        <h3>PARA O SEU CLIENTE</h3>
        <h3>COMO FUNCIONA O CREDITALLPAY® RECORRÊNCIA GARANTIDA</h3>
        <div class="row cm-how-steps">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon icon-botao"><i class="fas fa-dollar-sign"></i></div>
              <h4 class="title"><p class="fw-bold step-number">1</p></h4>
              <p class="description">A loja inclui o valor total da compra na plataforma Creditall, sem interferir com suas operações normais de cartão</p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
              <div class="icon icon-botao"><i class="fas fa-list-ol"></i></div>
              <h4 class="title"><p class="fw-bold step-number">2</p></h4>
              <p class="description">Define com o cliente o número de parcelas (até 12x). O cliente precisa ter apenas o valor da primeira parcela disponível no cartão</p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
              <div class="icon icon-botao"><i class="fas fa-calculator"></i></div>
              <h4 class="title"><p class="fw-bold step-number">3</p></h4>
              <p class="description">Análise de crédito para identificar perfil e potencial, com limites até 3x maiores</p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon icon-botao"><i class="fas fa-qrcode"></i></div>
              <h4 class="title"><p class="fw-bold step-number">4</p></h4>
              <p class="description">O valor de cada parcela é debitado mensalmente na fatura do cartão do cliente, mantendo seu limite disponível para outras compras</p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
              <div class="icon icon-botao"><i class="fas fa-shield-alt"></i></div>
              <h4 class="title"><p class="fw-bold step-number">5</p></h4>
              <p class="description">Sistema com mitigação de até 99% contra fraudes e chargebacks através de nossas ferramentas de inteligência antifraude</p>
            </div>
            <div class="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
              <div class="icon icon-botao"><i class="fas fa-check"></i></div>
              <h4 class="title"><p class="fw-bold step-number">6</p></h4>
              <p class="description">Garantimos o pagamento ao lojista mesmo em casos de problemas como troca de cartão ou ausência de limite do cliente</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div style="margin-top: 50px"></div>

  <section id="beneficios" class="beneficios">
    <div style="margin-top: 75px"></div>
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <div class="section-title-beneficios">
          <h2>BENEFÍCIOS NA RECORRÊNCIA GARANTIDA</h2>
        </div>
      </div>

      <div class="col-lg-12 d-flex justify-content-center">
        <div class="col-lg-10">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>AUMENTO EXPRESSIVO NAS VENDAS</h3>
                <p>
                  Os limites de créditos cedidos poderão ser até 12x maiores que os limites gerados pela operadora de cartão. 
                  Seu cliente não precisará se preocupar em manter limite disponível para eventuais compras e imprevistos futuros.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>GESTÃO E GARANTIA COMPLETA</h3>
                <p>
                  Além de garantir que o Parceiro receba 100% de suas vendas pela recorrência, a Creditall cuidará desde a análise de crédito, 
                  gestão e repasse de recebíveis, cobranças e afins, para que a energia do parceiro seja voltada ao seu negócio.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>DIFERENCIAL COMPETITIVO</h3>
                <p>
                  Diferencia-se diretamente em relação sua concorrência, pois terá melhores meios para fidelizar seus clientes.
                  Com condições mais flexíveis e vantajosas, você consegue oferecer uma experiência superior aos seus clientes.
                </p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4">
              <div class="info-box mb-4">
                <h3>OPERAÇÃO INDEPENDENTE</h3>
                <p>
                  Não mistura operação da Recorrência Garantida com o recebimento parcelado ou à vista do cartão de crédito que já pratica, 
                  pois todas as vendas na recorrência ocorrem dentro da plataforma da Creditall, evitando erros no processo de recebimentos por seus vendedores.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div style="margin-top: 50px"></div>

  <section id="vantagens" class="vantagens">
  <div style="margin-top: 75px"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6 justify-content-start content" data-aos="fade-right">
            <div class="section-title" data-aos="fade-up">
                <div style="margin-top: 25px"></div>
              <div class="section-title-vantagens">
                <h2>Vantagens</h2>
              </div>
              <div style="margin-left: 20px">
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Aumento nas vendas de forma saudável e sustentável.</p><br>
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Atende clientes não alcançados por outros meios de pagamento.</p><br>
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Proteção contra fraudes e chargebacks.</p><br>
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Compatível com todas as bandeiras de cartão de crédito.</p><br>
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Operação independente que não interfere no uso regular do cartão.</p><br>
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Flexibilidade no limite - cliente precisa ter apenas o valor da parcela disponível.</p><br>
                  <p style="font-size: 14px"><i class="bi bi-check2 text-danger"></i> Garantia total contra inadimplência através do sistema de ressarcimento.</p>
                  <div style="margin-top: 25px"></div>
              </div>
              <div style="margin-top: 25px"></div>
            </div>
          </div>

          <div class="col-lg-6 d-flex justify-content-center align-items-center" data-aos="fade-left">
            <img src="assets/images/aplicacoes_pix.jpg" class="img-fluid rounded shadow" style="height: 100%" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>

  <Parcerias />

  <div style="margin-top: 50px"></div>
  <section id="queroser" class="queroser">

    <div class="section-title-quero">
      <h2>Quero ser Cliente</h2>
    </div>
    <div class="mb-5" data-aos="fade-right">
      <h5 class="text-center">
        Seja parceiro e venda muito mais com o<br>
        CreditallPay® Recorrência Garantida
      </h5>
      <div class="d-grid gap-2 col-4 mx-auto" style="margin-top: 25px">
        <a href="/cadastro-pix-parcelado-garantido" class="btn btn-danger" style="border-radius: 5px;">
          Fale conosco
        </a>
      </div>
    </div>
  </section>

  <a href="https://wa.me/5544991435010" target="_blank" class="whatsapp-float d-flex align-items-center justify-content-center" 
    style="position: fixed; bottom: 20px; right: 20px; background: #25d366; color: white; width: 50px; height: 50px; border-radius: 50%; font-size: 24px; box-shadow: 2px 2px 5px;">
      <i class="bi bi-whatsapp"></i>
  </a>

  <div style="margin-top: 30px;"></div>
  <FooterPixParcelado />
</template>

<script>
import FooterPixParcelado from "../components/FooterPixParcelado.vue";
import HeaderRecorrencia from "../components/HeaderRecorrencia.vue";
import Parcerias from "../components/Parcerias.vue";

export default {
  name: "Recorrencia",
  components: {
    HeaderRecorrencia,
    FooterPixParcelado,
    Parcerias,

  },
};

</script>

<style scoped>
.section-title-inicio {
  text-align: center;
  padding: 20px 0;
  position: relative;
}

.section-title-inicio h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-inicio span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
}

.section-title-inicio p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.icon-box a#botao.btn.btn-danger.text-light {
  background-color:#BF1B39;
  position: absolute;
  bottom: 0;
  border: #fff;
}

.comofuncionaparceiro .icon-box {
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  background: #fefefe;
  box-shadow: 0px 50px 90px 0px rgba(110, 123, 131, 0.1);
  border-radius: 18px;
  border-bottom: 5px solid #fff;
}

.comofuncionaparceiro .icon-box .icon {
  width: 58px;
  height: 58px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.comofuncionaparceiro .icon-box .icon-botao{
  width: 44px;
  height: 44px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.comofuncionaparceiro .icon-box img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -5rem;
  left: 91%;
}

.comofuncionaparceiro .icon-box .icon i {
  font-size: 28px;
}

.comofuncionaparceiro .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.comofuncionaparceiro .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.comofuncionaparceiro .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.comofuncionaparceiro .icon-box:hover {
  transform: translateY(-10px);
  border-color: #BF1B39;
}

.comofuncionaparceiro .icon-box:hover h4 a {
  color: #BF1B39;
}

.section-title-comofuncionaparceiro {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title-comofuncionaparceiro h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-comofuncionaparceiro span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 1;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.comofuncionacliente {
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 60px 0;
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/assets/images/recorrencia1.png") fixed center center;
  background-size: cover;
}

.comofuncionacliente .page-center {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.comofuncionacliente h3 {
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
  padding: 8px;
  border-radius: 8px;
  color: #fff;
  letter-spacing: -0.5px;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 15px;
}

.comofuncionacliente .icon-box {
  margin-bottom: 15px;
  text-align: center;
  padding: 0 10px;
}

.comofuncionacliente .icon-botao {
  background: rgba(255,255,255,0.81);
  width: 60px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.5s;
  color: #BF1B39;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
}

.comofuncionacliente .icon-botao i {
  font-size: 30px;
}

.comofuncionacliente .step-number {
  font-size: 22px;
  background: rgba(255,255,255,0.85);
  padding: 6px 16px;
  border-radius: 5px;
  margin: 10px 0;
  color: #333;
  display: inline-block;
}

.comofuncionacliente .description {
  font-size: 16px;
  background: rgba(0,0,0,0.6);
  padding: 15px;
  border-radius: 8px;
  line-height: 1.5;
  min-height: 140px;
}

 
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comofuncionacliente h3 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 991px) {
  .comofuncionacliente h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 576px) {
  .comofuncionacliente h3 {
    font-size: 20px;
  }
}

.beneficios .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 40px 40px 30px 40px;
}

.beneficios .info-box i {
  font-size: 52px;
  color: #BF1B39;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f5a0a0;
}

.beneficios .info-box h3 {
  font-size: 20px;
  color: #444444;
  font-weight: 700;
  margin: 10px 0;
}

.beneficios .info-box p {
  padding: 20px;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .beneficios .info-boxs span {
      font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .beneficios .info-box span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .beneficios .info-box span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .beneficios .info-box span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .beneficios .info-box span {
      font-size: 20px;
      line-height: 0;
    }
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title-beneficios {
  text-align: center;
  padding: 20px;
  position: relative;
}

.section-title-beneficios h2 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-beneficios span {
  position: absolute;
  top: 0px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
}

.section-title-beneficios p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-vantagens {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title-vantagens h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-vantagens span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title-vantagens p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-vantagens span {
      font-size: 40px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-vantagens span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .section-title-vantagens span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-vantagens span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-vantagens span {
      font-size: 20px;
      line-height: 0;
    }
}

.icon-box a#botao.btn.btn-danger.text-light {
  background-color:#BF1B39;
  position: absolute;
  bottom: 0;
  border: #fff;
}

.vantagens .icon-box {
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  background: #fefefe;
  box-shadow: 0px 50px 90px 0px rgba(110, 123, 131, 0.1);
  border-radius: 18px;
  border-bottom: 5px solid #fff;
}

.vantagens .icon-box .icon {
  width: 64px;
  height: 64px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.vantagens .icon-box .icon-botao{
  width: 44px;
  height: 44px;
  background: #BF1B39;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.vantagens .icon-box img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: -5rem;
  left: 91%;
}

.vantagens .icon-box .icon i {
  font-size: 28px;
}

.vantagens .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.vantagens .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.vantagens .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.vantagens .icon-box:hover {
  transform: translateY(-10px);
  border-color: #BF1B39;
}

.vantagens .icon-box:hover h4 a {
  color: #BF1B39;
}

.section-title-vantagens {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title-vantagens h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-vantagens span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-seguranca {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title-seguranca h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-seguranca span {
  position: absolute;
  top: 1px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 1;
}

.section-title-seguranca p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.section-title-parceiros {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title-parceiros h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-parceiros span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title-parceiros p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.logos {
  background: #f2f2f2;
  padding: 15px 0;
  text-align: center;
}

.logos img {
  width: 50%;
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.logos img:hover {
  filter: none;
  transform: scale(1.2);
}

section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title-quero {
  text-align: center;
  padding: 30px 0;
  position: relative;
}

.section-title-quero h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #191919;
  position: relative;
  z-index: 2;
}

.section-title-quero span {
  position: absolute;
  top: 30px;
  color: #f4f4f4;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 52px;
  text-transform: uppercase;
  line-height: 0;
}

.section-title-quero p {
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.vantagens .icon-box .icon i {
  font-size: 28px;
}

.vantagens .icon-box h4 {
  font-weight: 700;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 24px;
}

.vantagens .icon-box h4 a {
  color: #282828;
  transition: ease-in-out 0.3s;
}

.vantagens .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.vantagens .icon-box:hover {
  transform: translateY(-10px);
  border-color: #BF1B39;
}

.vantagens .icon-box:hover h4 a {
  color: #BF1B39;
}



@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-vantagens span {
        font-size:55px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-vantagens span {
        font-size:48px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title-vantagens span {
        font-size:35px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-vantagens span {
        font-size: 30px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-vantagens span {
        font-size:25px;
        top: 30px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-seguranca span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-seguranca span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media(max-width:767px){
    .section-title-seguranca span {
      font-size: 38px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-seguranca span {
      font-size: 35px;
      top: 30px;
      line-height: 0;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-seguranca span {
      font-size: 28px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-parceiros span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-parceiros span {
      font-size: 38px;
    }
}

@media(max-width:767px){
    .section-title-parceiros span {
      font-size: 35px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-parceiros span {
      font-size: 32px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-parceiros span {
      font-size: 30px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-quero span {
      font-size: 42px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-quero span {
      font-size: 38px;
    }
}

@media(max-width:767px){
    .section-title-quero span {
      font-size: 35px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-quero span {
      font-size: 32px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-quero span {
      font-size: 30px;
    }
}


@media only screen and (min-width:992px) and (max-width:1199px){
    .section-title-comofuncionaparceiro span {
        font-size:28px;
        top: 30px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .section-title-comofuncionaparceiro span {
        font-size:28px;
        top: 30px;
    }
}

@media(max-width:767px){
    .section-title-comofuncionaparceiro span {
        font-size:28px;
        top: 30px;
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .section-title-comofuncionaparceiro span {
        font-size: 28px;
        top: 30px;
    }
}

@media only screen and (min-width:440) and (max-width:576px){
    .section-title-comofuncionaparceiro span {
        font-size:25px;
        top: 30px;
    }
}


</style>