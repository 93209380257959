<template>
  <section id="home" class="header_area">
    <div id="sticky" class="sticky">
      <div class="container d-flex align-items-center justify-content-between" style="width: 100%">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="/">
                <img id="logo" href="../views/Home.vue" src="../../public/assets/images/logo_creditall.png" alt="Logo Creditall"/>
              </a>

              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ml-auto">
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="/pix-parcelado-garantido#inicio">Início</a></li>
                  <li class="nav-item dropdown"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#comofunciona"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Como Funciona<i class="bi bi-chevron-down position-absolute start-100"></i></div></a>
                   <ul>
                      <li><a style="font-size: 12px; ;white-space: nowrap;" class="page-scroll" href="#comofuncionaparceiro"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Para Parceiros</div></a></li>
                      <li><a style="font-size: 12px; ;white-space: nowrap;" class="page-scroll" href="#comofuncionacliente"><div data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Para Clientes</div></a></li>
                    </ul></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="/pix-parcelado-garantido#beneficios">Benefícios</a></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="/pix-parcelado-garantido#vantagens">Vantagens</a></li>
                  <!--<li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="#vantagens">Vantagens</a></li>-->
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="/pix-parcelado-garantido#parceiros">Parceiros</a></li>
                  <li class="nav-item"><a style="font-size: 13px; margin-top:10px;" class="page-scroll" href="/pix-parcelado-garantido#queroser">Quero ser Cliente</a></li>
                  <li class="nav-item">
                    <div class="d-grid">
                        <button class="btn btn-primary" type="button" data-v-2c7a06c3="" style="font-family: poppins, sans-serif; font-size: 12px; margin-top: 10px; font-weight: 400; color: rgb(255, 255, 255); background: rgb(191, 27, 57); padding: 9px; right: 1rem; left: 0px; white-space: nowrap; margin-bottom: 15px; border-radius: 8px;">
                        Acessar o sistema
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopBarPPG",
  methods:{
        sistema(){
          window.open('https://www.siscredit.com.br/', '_blank');
        }
      }
};
</script>

<style scoped>
#sticky {
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 320px) {
  #sticky {
    position: fixed;
    margin-top: 0;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  #sticky {
    position: fixed;
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  #nav {
    width: 100%;
    margin: 0 auto;
  }
}

.sticky {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:999;
    -webkit-transition:all .3s ease-out 0s;
    -moz-transition:all .3s ease-out 0s;
    -ms-transition:all .3s ease-out 0s;
    -o-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s
}

.sticky .navbar {
  padding: 5px 0;
}

.sticky .navbar-toggler .toggler-icon{
    background-color:#222
}

.navbar {
  padding: 50px 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  width: 150px;
}

/* media */
.navbar-toggler {
  padding: 0;
}

.navbar-toggler:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  color:black;
  background-color: #fff;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

/* media */

.navbar-nav .nav-item {
  position: relative;
  margin-left: 40px;
}

/* media */

.navbar-nav .nav-item a {
  font-size: 13px;
  margin-top: 10px;
  font-weight: 400;
  color: #444444;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10px 0;
  position: relative;
}

.navbar-nav .nav-item a::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width:768px) and (max-width:991px){
    .navbar-nav .nav-item a::before{
        display:none
    }
}

@media(max-width:767px){
    .navbar-nav .nav-item a::before{
        display:none
    }
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .navbar-nav .nav-item {
        margin-left: 18px;
    }
}

@media only screen and (min-width:992px) and (max-width:1199px){
    .navbar-nav .nav-item a{
        font-size:10px;
    }
}

@media only screen and (min-width:768px) and (max-width:991px){
    .navbar-nav .nav-item a{
        display:block;
        border-bottom: 1px solid rgb(203, 214, 220);
        padding: 20px 0;
        color:#222
    }
}

@media(max-width:767px){
    .navbar-nav .nav-item a{
        display:block;
        border-bottom: 1px solid rgb(203, 214, 220);
        padding:20px 0;
        color:#222
    }
}

@media only screen and (min-width:576px) and (max-width:767px){
    .navbar-nav .nav-item a{
        display:block;
        border-bottom: 1px solid rgb(203, 214, 220);
        padding: 20px 0;
        color:#222;
        font-size:13px;
    }
}


@media only screen and (min-width:440) and (max-width:576px){
    .navbar-nav .nav-item a{
        display:block;
        border-bottom: 1px solid rgb(203, 214, 220);
        padding: 20px 0;
        color:#222;
        font-size:13px;
    }
}


.btn {
  position: relative;
  display: block;
  color: white;
  font-size: 14px;
  font-family: "montserrat";
  text-decoration: none;
  margin: 30px 0;
  border: 2px solid #BF1B39;
  padding: 14px 60px;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
}
.btn::before {
  background: #BF1B39;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
}

.btn1::before {
  width: 0%;
  height: 100%;
}

.btn1:hover::before {
  width: 100%;
}

#servicoSelect {
  font-size: 16px;
  font-weight: 400;
  padding-top: 9px;
  margin-left: 40px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10 px 0;
  position: relative;
}
.sticky #servicoSelect {
  color: #222;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255 em;
  color: #fff;
  vertical-align: 0.255 em;
  content: "";
  border-top: 0.3 em solid;
  border-right: 0.3 em solid transparent;
  border-bottom: 0;
  border-left: 0.3 em solid transparent;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul li a {
  min-width: 100%;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #191919;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #fff;
  background: #BF1B39;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

/* media */

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #BF1B39;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

@media screen and (device-aspect-ratio: 40/71) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 375/667) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 360/640) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 375/812) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 280/653) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 360/760) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 360/800) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 320/533) {
  #home {
    width: 100%;
  }
}


@media screen and (device-aspect-ratio: 320/480) {
  #home {
    width: 100%;
  }
}
@media screen and (device-aspect-ratio: 240/320) {
  #home {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

.navbar-brand{
    padding:0
}

.navbar-brand img{
    margin-top: 0px;

}

@media(max-width:767px){
    .navbar-brand img{
        width:130px
    }
}
</style>
