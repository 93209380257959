<template>
  <TopBar />
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="col-lg-12">
        <ol>
          <li class="text-danger"><a href="/">Início</a></li>
          <li><a href="/#servicos">Produtos e Serviços</a></li>
        </ol>
        <h2>Boleto Garantido</h2>
      </div>
    </div>
  </section>

  <section class="inner-page mt-5">
    <div class="container">


      <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
          <div class="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h4 style="border-bottom: 1px solid red">Vídeo Comercial</h4>
          </div>
          <iframe src="https://www.youtube.com/embed/uDF8VOzrTFo" width="100%" height="400"></iframe>
        </div>

        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
          <div class="col-lg-4 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h4 style="border-bottom: 1px solid red">Como Funciona?</h4>
          </div>
          <iframe src="https://www.youtube.com/embed/pZIyiUpY4YQ" width="100%" height="400"></iframe>
        </div>
      </div>
      </div>
      <div style="margin-top: 75px"></div>


      <div data-aos="fade-right">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h5 style="border-bottom: 1px solid red">Sobre o Produto</h5>
              <br />
              <div class="col-lg-11">
                <p style="font-size: 13px">
                    O Boleto Garantido é uma solução destinada às empresas de varejo, atacado e comercio eletrônico
                    que desejam efetuar vendas parceladas em até 12 vezes por meio do Boleto Bancário.
                </p>
                <p style="font-size: 13px">
                    O Boleto Bancário devido a sua versatilidade, facilidade de controle
                    e pagamento para o Consumidor é um dos principais meios de venda no
                    comércio em geral.
                </p>
                <p style="font-size: 13px">
                    Destacam-se principalmente os segmentos voltados para: Equipamentos
                    e peças em geral, Eletro-Eletrônicos, Móveis & Decoração, Calçados,
                    Confecções entre outros que tenham como característica o parcelamento de
                    suas vendas.
                </p>
                <p style="font-size: 13px">
                    O Boleto Garantido é a opção de pagamento em seu comércio.
                </p>
                <p style="font-size: 13px">
                    Uma linha de crédito fácil, sem burocracia e com taxas competitivas,
                    que irá ajudar a aumentar as suas vendas, seus clientes e sua
                    lucratividade com maior fidelidade.
                </p>
                <p style="font-size: 13px">
                    Creditall Boleto Garantido é a forma mais segura e eficaz para
                    agilizar o processo de concessão de crédito e facilitar a gestão de
                    contas a receber na sua empresa, de forma prática e com baixo custo.
                    Trata-se de uma ferramenta de crédito poderosa que oferece crédito
                    direto ao consumidor em até 12 parcelas para pagamento.
                </p>
                <p style="font-size: 13px">
                    Ao implementar esta solução sua empresa contará com ferramentas
                    avançadas de análise e concessão de crédito, avaliação
                    comportamental e perfil de compras, sistema de alerta e prevenção a
                    fraudes para a correta identificação do consumidor, eliminando risco
                    e proporcionando maior lucratividade em suas operações de crédito.
                </p>
                <p style="font-size: 13px">
                    Para o seu cliente, além de crédito pré-aprovado e prazo para
                    pagamento, contará com atendimento personalizado e sem burocracia.
                </p>
                <p style="font-size: 13px">
                    Com a solução Creditall Boleto Garantido seu comércio obterá aumento
                    significativo em suas vendas, e o melhor, através do nosso sistema
                    você passa a se relacionar mais com seus clientes reforçando a sua
                    marca e alterando o hábito de compra dos mesmos, sua empresa terá
                    acesso a relatórios completos de comportamento por:
                </p>

                <div class="row">
                  <div class="col-md-6" style="margin-left: 20px">
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Quantidade de compras</p>
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Valor Médio por compra</p>
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Prazo Médio e Parcelamento Máximo utilizado</p>
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Clientes que mais Consomem</p>
                  </div>
                  <div class="col-md-4" style="margin-left: 20px">
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Dia da última compra</p>
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Clientes inativos</p>
                    <p style="font-size: 13px"><i class="bi bi-check-circle-fill text-danger" style="color: #BF1B39"></i> Dados Cadastrais</p>
                    <div style="margin-top: 75px"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-left">
                <h5 style="border-bottom: 1px solid red">As informações Permitirão</h5>
                <br />
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Conhecer os clientes.</p>
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Criar estratégias de vendas.</p>
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Emissão de etiquetas para envio de mala direta.</p>
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Entre outras informações que permitirão que sua empresa conheça o hábito de compras de seus clientes proporcionando maior rentabilidade e aumento de suas vendas.</p>
                <div style="margin-top: 50px"></div>

                <h5 style="border-bottom: 1px solid red">Pontos Positivos</h5>
                <br />
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Atrai os clientes e incrementa as vendas.</p>
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Estreita laços entre o cliente e a empresa.</p>
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Estimula o cliente a retornar para novas compras.</p>
                <p style="font-size: 13px"><i class="bi bi-check text-danger" style="color: #BF1B39"></i> Serve para diferenciar a empresa da concorrência.</p>
                </div>
          </div>
        </div>



          <div style="margin-top: 50px"></div>
            <h5 style="border-bottom: 1px solid red">Benefícios do Produto</h5>
            <div style="margin-top: 50px"></div>
            <div class="col-lg-12">
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text text-muted">
                      <i class="fas fa-money-bill text-danger" style="color: #BF1B39;"></i> 100% de ressarcimento em caso de inadimplência
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text text-muted">
                      <i class="fas fa-user-secret text-danger" style="color: #BF1B39;"></i> Inteligência de crédito e anti-fraude
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text text-muted" style="padding-right: 1.5rem">
                      <i class="fas fa-chart-line text-danger" style="color: #BF1B39;"></i> Aumento nos recebimentos das vendas a prazo
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text text-muted">
                      <i class="fas fa-balance-scale text-danger" style="color: #BF1B39;"></i> Maior lucratividade em suas transações comerciais
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text text-muted">
                      <i class="fas fa-user-check text-danger" style="color: #BF1B39;"></i> Identificação do perfil e potencial do consumidor
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card h-100">
                  <div class="card-body">
                    <p class="card-text text-muted">
                      <i class="fas fa-clock text-danger" style="color: #BF1B39;"></i> Diminuição no tempo médio do atendimento
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>


        <div style="margin-top: 75px"></div>
        <h5 class="text-center">Entre em contato para maiores informações</h5>
        <div class="d-grid gap-2 col-4 mx-auto" style="color: #BF1B39;">
          <a href="/quero-ser-cliente" class="btn btn-danger" style="border-radius: 0px;">Fale conosco</a>
          <div style="padding-top: 3rem"></div>
        </div>


      <ul class="col-lg-12 border-top"></ul>

      <div class="col-lg-12">
        <div class="alert alert-warning" role="alert">
          <p>
            <strong>Aviso! -</strong> As condições comerciais apresentadas em
            nosso vídeo são para empresas com movimentação igual ou superior a
            R$ 200.000,00 (Duzentos mil reais). Para demais volumes de vendas, entre em contato com
            nossa área comercial para receber uma proposta de acordo com seu
            perfil.
          </p>
        </div>
      </div>
      </div>
    </div>
    <div style="margin-top: 75px"></div>
  </section>
  <Footer />
</template>

<script>
import Footer from "../components/Footer.vue";
import TopBar from '../components/TopBar.vue';
export default {
  name: "BoletoGarantido",
  components: {
    TopBar,
    Footer,
  },
    mounted() {
    if (window.localStorage) {
                if (!localStorage.getItem('reload')) {
                    localStorage['reload'] = true;
                    window.location.reload();
                } else {
                    localStorage.removeItem('reload');
                }
    }
  }
};
</script>

<style scoped>
.breadcrumbs {
  padding: 45px 0;
  margin-top: 4rem;
  background: #efefef;
}
@media only screen and (max-width: 320px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    position: relative;
    width: 100%;
    margin-top: 44px;
  }
}
.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #333333;
  content: "/";
}
.card-body {
  background: #f2f2f2;
  opacity: 0.9;
}
</style>
